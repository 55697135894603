import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Divider, Checkbox, Button, Dialog, Paper, DialogTitle, DialogContent, DialogActions, Select, MenuItem } from "@mui/material";
import offensesJSON from '../../offenses.json';
import lawyersJSON from '../../lawyers.json';
import { green, yellow, pink } from '@mui/material/colors';
import Laws from "./Laws"
//import Procedure from './Procedure';


const calculatePenalty = (offenses) => {
	let fine = 0
	let community_service = 0
	
	offenses.forEach((offense) => {
		let calculatedFine = 0
		let calculatedCommunityService = 0
		if (offense.quantity === true)
		{
			let fine_value = offense.penalty.fine || 0;
			if (!offense.penalty.fine && offense.penalty.fine_q)
			{
				let fine_index = 0;
				offense.penalty.item_q.forEach((item, index) => {
					if ((offense.q || 0) > item)
						fine_index = index + 1
				})
				fine_value = offense.penalty.fine_q[fine_index] || 0
			}
			if (offense.penalty.fine_per_unit)
				calculatedFine = fine_value * (offense.q || 0)
			else if (offense.penalty.fine_per_100_dollars)
				calculatedFine = fine_value * Math.floor(( (offense.q || 0) / 100 ))
			else
				calculatedFine = fine_value

				calculatedCommunityService = offense.penalty.community_service || 0
		}
		else
		{
			calculatedFine = offense.penalty.fine || 0
			calculatedCommunityService = offense.penalty.community_service || 0
		}

		if (offense.complicity === true)
		{
			calculatedFine = Math.floor( calculatedFine / 2)
			calculatedCommunityService = Math.floor( calculatedCommunityService / 2)
		}


		fine += calculatedFine
		if (calculatedCommunityService > community_service)
			community_service = calculatedCommunityService
		console.log(fine)
		//fine += offense.penalty.fine || 0
		//community_service += offense.penalty.community_service || 0
	})

	return {"fine":fine, "community_service":community_service}
}

const offenseToText = (offense) => {
	const vowels = "aeiouAEIOU"; 
	let vowel = vowels.indexOf(offense.name[0]) !== -1
	let text = offense.law_number
	text += ( offense.complicity === true ? " && Article 162-1 : " : " : ")
	text += ( offense.complicity === true ? (vowel ? "Complicité d'" : "Complicité de ") + offense.name.toLowerCase() : offense.name )
	text += ( offense.quantity === true ? " ( " + (offense.q || 0) + " ) " : "") 
	return text
}

const numberWithSpaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}



export default function LawyerCallForm() {

	const [agentCallerMatricule, setAgentCallerMatricule] = useState("E-00")
	const [arrestAgentMatricule, setArrestAgentMatricule] = useState("D-00")
	const [offender, setOffender] = useState("Paul Defès")
	const [detentionStartTime, setDetentionStartTime] = useState("00:00")
	const [lawyerRequestTime, setLawyerRequestTime] = useState("00:00")
	const [lawyer, setLawyer] = useState(0)
	const [confirmation, setConfirmation] = useState(false)
	const [offenses, setOffenses] = useState([])

	const [, updateState] = React.useState();
	const forceUpdate = React.useCallback(() => updateState({}), []);

	const handleAgentCallerMatricule = (event) => {
		setAgentCallerMatricule(event.target.value)
	}
	const handleDetentionStartTime = (event) => {
		setDetentionStartTime(event.target.value)
	}
	const handleLawyerRequestTime = (event) => {
		setLawyerRequestTime(event.target.value)
	}
	const handleArrestAgentMatricule = (event) => {
		setArrestAgentMatricule(event.target.value)
	}
	const handleOffender = (event) => {
		setOffender(event.target.value)
	}
	const handleOffenses = (offense, check, complicity) => {
		
		let _offenses = offenses
		if (check === true)
		{
			offense.complicity = complicity
			_offenses.push(offense)
		}
		else
		{
			let index = _offenses.indexOf(offense)
			_offenses.splice(index, 1)
		}
		forceUpdate()
		setOffenses(_offenses)
	}
	const penalty = calculatePenalty(offenses)
	const copyToClipboard = () => {
		let appel = "Monsieur/Madame **" + String(offender) + "** fait appel à vous <@" + lawyersJSON[lawyer].ID + "> !\n"
		appel += "Le contrevenant a ete mis en garde a vu a **" + String(detentionStartTime) + "** et a demander a un avocat a **" + String(lawyerRequestTime) + "**.\n"
		appel += "Les chefs d'inculpations retenu sont les suivants : \n" + offenses.map((item) => { return " \\- " + offenseToText(item) + "\n"}).join("")
		appel += "Le contrevenant encourt un maximum de **" + String(numberWithSpaces(penalty.fine)) + "** dollars d'amende et **" + String(penalty.community_service) + "** travaux d'intérêt généraux.\n"
		appel += "L'agent en charge de son arrestation est l'agent **" + String(arrestAgentMatricule) + "** et l'agent responsable de cet appel avocat est l'agent **" + String(agentCallerMatricule) + "**\n"
		
		//appel += "L'appel est effectuer par " + String(agent)
		//appel += "Nom et prénom du client : " + String(client) + "\n"
		//appel += "Amande maximum : " + numberWithSpaces(offenses.reduce((accumulator, a) => {return accumulator + ( a.penalty.fine || 0)}, 0)) + " dollars\n"
		//appel += "Chef d'inculpation : " + offenses.map((item, index) => { return index === offenses.length - 1 ? item.name : item.name + " + "}).join("") + "\n"
		//appel += "Chef d'inculpation : " + offenses.map((item, index) => { return index === offenses.length - 1 ? offenseToText(item) : offenseToText(item) + " + "}).join("") + "\n"
		//appel += "Avocat : <@" + lawyersJSON[avocat].ID + ">\n"
		navigator.clipboard.writeText(appel)
	}
	const handleLawyer = (event) => {
		setLawyer(event.target.value)
	}

	useEffect(() => {
		const d = new Date()
		let hours = String(d.getHours())
		let minutes = String(d.getMinutes())
		console.log((hours.length === 1 ? "0" + hours : hours) + ":" + (minutes.length === 1 ? "0" + minutes : minutes))
		setLawyerRequestTime((hours.length === 1 ? "0" + hours : hours) + ":" + (minutes.length === 1 ? "0" + minutes : minutes))
		setDetentionStartTime((hours.length === 1 ? "0" + hours : hours) + ":" + (minutes.length === 1 ? "0" + minutes : minutes))
	}, [])

	return (
		<Box sx={{
			display:"flex",
			alignItems:"center",
			justifyContent:"center",
			align:"center",
			maxWidth:"80%",
			margin:"5% auto 5% auto"
		}}>






			<Dialog open={confirmation}>
				<Paper sx={{
					color:"text.primary",
				}}>
					<DialogTitle>Confirmation</DialogTitle>
					<DialogContent>
						<Typography variant="h6">
							Voici le résumé d'appel:
						</Typography>
						<Paper elevation={5} sx={{"padding":"10px"}}>
							<Typography>
								Matricule de l'agent réalisant l'appel : {String(agentCallerMatricule)}
							</Typography>
							<Divider/>
							<Typography>
								Matricule de l'agent en charge de l'arrestation : {String(arrestAgentMatricule)}
							</Typography>
							<Divider/>
							<Typography>
								Heure de la mise en garde à vue : {String(detentionStartTime)}
							</Typography>
							<Divider/>
							<Typography>
								Heure de la demande du contrevenant de l'appel avocat : {String(lawyerRequestTime)}
							</Typography>
							<Divider/>
							<Typography>
								Identité du contrevenant : {String(offender)}
							</Typography>
							<Divider/>
							<Typography>
								Amende maximum : {numberWithSpaces(penalty.fine)} dollars
							</Typography>
							<Divider/>
							<Typography>
								T.I.G maximum : {penalty.community_service} T.I.G ( {penalty.community_service * 40} secondes ou {Math.floor(penalty.community_service * 40 / 60)} minutes)
							</Typography>
							<Divider/>
							<Typography>
								Chef d'inculpation : {offenses.map((item, index) => { return <div key={index}>{offenseToText(item)}</div>})}
							</Typography>
							<Divider/>
							<Typography>
								Avocat : {lawyersJSON[lawyer].name}
							</Typography>
						</Paper>
						<Typography variant="h6">
							vérifiez tout pour être sûr de ne pas vous tromper !
						</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={copyToClipboard} sx={{color:"primary.light"}}>Copier</Button>
						<Button onClick={()=>setConfirmation(false)} sx={{color:"primary.light"}}>Fermer</Button>
					</DialogActions>
				</Paper>
			</Dialog>








			
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Divider role="presentation">
						<Typography variant="h3">
							Appel Avocat
						</Typography>
					</Divider>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6" component="div">
						matricule de l'agent réalisant l'appel : 
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<TextField
						fullWidth
						label="matricule"
						type="text"
						variant="outlined"
						onChange={handleAgentCallerMatricule}
						value={agentCallerMatricule}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6" component="div">
						matricule de l'agent en charge de l'arrestation : 
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<TextField
						fullWidth
						label="matricule"
						type="text"
						variant="outlined"
						onChange={handleArrestAgentMatricule}
						value={arrestAgentMatricule}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6" component="div">
						Nom et prénom du contrevenant :
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<TextField
						fullWidth
						label="contrevenant"
						type="text"
						variant="outlined"
						onChange={handleOffender}
						value={offender}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6" component="div">
						Heure de la mise en garde a vue :
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<TextField
						fullWidth
						label="heure"
						type="time"
						variant="outlined"
						onChange={handleDetentionStartTime}
						value={detentionStartTime}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6" component="div">
						Heure de la demande du contrevenant de l'appel avocat :
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<TextField
						fullWidth
						label="heure"
						type="time"
						variant="outlined"
						onChange={handleLawyerRequestTime}
						value={lawyerRequestTime}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6" component="div">
						Avocat :
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Select fullWidth onChange={handleLawyer} value={lawyer}>
						{
							lawyersJSON.map((item, index) => {
								return (<MenuItem key={index} value={index}>{item.name}</MenuItem>)
							})
						}
					</Select>
				</Grid>
				<Grid item xs={12}>
					<Divider role="presentation">
						<Typography variant="h6">
							Chef d'inculpation
						</Typography>
					</Divider>
				</Grid>
				<Grid item xs={1}>
					<Checkbox
						checked
						disabled
						style={{padding:0}}
						sx={{
							color: green[800],
							'&.Mui-checked': {
							color: green[600],
							},
						}}
					/>
				</Grid>
				<Grid item xs={11}>
					<Typography sx={{color:green[800]}} variant="h6">
						Accuser du fait
					</Typography>
				</Grid>
				<Grid item xs={1}>
					<Checkbox
						checked
						disabled
						style={{padding:0}}
						sx={{
							color: yellow[800],
							'&.Mui-checked': {
							color: yellow[600],
							},
						}}
					/>
				</Grid>
				<Grid item xs={11}>
					<Typography sx={{color:yellow[800]}}variant="h6">
						Accuser de complicité du fait
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography sx={{color:pink[400], textDecoration: 'underline'}} variant="h6" display="inline">
						Code rose ( Appel procureur )
					</Typography>
				</Grid>
				{
					Object.keys(offensesJSON).map((item, index) => {
						return (
							<Laws key={index} item={item} handleOffenses={handleOffenses}/>
						)
					})
				}
				<Grid item xs={10}/>
				<Grid item xs={2}>
					<Button variant="contained" onClick={() => {setConfirmation(true)}}>
						Confirmer
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
}