//import Header from "./Header";
import DefaultView from "./Applications/DefaultView";
import LawyerCallForm from "./Applications/LawyerCallForm/LawyerCallForm";
import FactReportForm from "./Applications/FactReportForm";
import ArrestReportForm from "./Applications/ArrestReportForm";
import ArrestReport from "./Renderers/ArrestReport";
import FactReport from "./Renderers/FactReport";
import { Auth } from "./Applications/Auth";
import GDoc from "./Tests/gdoc";
import GDoc2 from "./Tests/gdoc2";
import React from "react";
import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material";

export default function App() {

	//https://bareynol.github.io/mui-theme-creator/
	const darkTheme = createTheme({
		palette:{
			mode:"dark",
			primary: {
				main: '#6170c5',
			},
			secondary: {
				main: '#f50057',
			},
			background: {
				default:"#303030",
				paper:"#424242"
			}
		},
	})

	const lightTheme = createTheme({
		palette:{
			mode:"light",
		},
	})
	




	const urlToView = () => {
		if (false)
		{
			switch(window.location.pathname)
			{
				
				case "/LawyerCallForm":
					return (<LawyerCallForm/>)
				case "/ReportForm":
					return (<FactReportForm/>)
				case "/ArrestReportForm":
					return (<ArrestReportForm/>)
				case "/ArrestReport":
					return (<ArrestReport/>)
				case "/FactReport":
					return (<FactReport/>)
				case "/Auth":
					return (<Auth/>)
				case "/gdoc":
					return (<GDoc/>)
				case "/gdoc2":
					return (<GDoc2/>)
				default:
					return (<DefaultView/>)
			}
		}else{
			return (<LawyerCallForm/>)
		}
	}

	const urlToDark = () => {
		switch(window.location.pathname)
		{
			case "/ArrestReport":
			case "/FactReport":
				return false
			case "/ArrestReportForm":
			case "/ReportForm":
			case "/LawyerCallForm":
			default:
				return true
		}
	}


	//backgroundColor:"#282c34",
	//color:"white"


	return (
		<ThemeProvider theme={urlToDark() ? darkTheme : lightTheme}>
			<CssBaseline/>
			<Box sx={{
				width:"100vw",
        height:"100vh",
				overflowX:"hidden",
				overflowY:"scroll",
				display:"flex",
				flexDirection:"column"
			}}>
				<Box sx={{
					width:1,
					flexGrow:1,
				}}>
					{urlToView()}
				</Box>
			</Box>
		</ThemeProvider>
	)
} 