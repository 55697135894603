import { Box, Typography } from "@mui/material";



function ReportForm(props)
{
	return (
		<Box sx={{
			display:"flex",
			alignItems:"center",
			justifyContent:"center",
			height:1
		}}>
			<Typography variant="h6" component="div">
				Proof of concept. Bienvenue :)
			</Typography>
		</Box>
	)
}

export default ReportForm;