import React, { useState } from 'react';
import { Stack, Grid, Typography, Divider } from "@mui/material";
import offensesJSON from '../../offenses.json';
import DoubleCheck from "./DoubleCheck"



export default function Laws (props) {
	const [active, setActive] = useState(0)
	const item = props.item
	const handleCheck = (e, item, complicity) => {
		setActive(active + (e.target.checked ? 1 : -1));
		props.handleOffenses(item, e.target.checked, complicity)
	}
	return (
		<Grid item xs={4}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Divider role="presentation">
						<Typography variant="h6">
							{offensesJSON[item].name}
						</Typography>
					</Divider>
				</Grid>
					<Typography variant="h6" color={"error"} fontSize={12} style={{visibility: (active > 2 ? "visible" : "hidden")}}>
						Seules deux amendes doivent être cochées par catégorie. sinon un avocat peut plaider le vice de procédure !
					</Typography>
				<Stack sx={{width:1}} direction={"column"} alignItems={"flex-start"} alignContent={"flex-start"} divider={<Divider orientation="horizontal" flexItem/>}>
				{
					offensesJSON[item].laws.map((_item, index) => {
						return (
							<DoubleCheck
								handleCheck={handleCheck}
								item={item}
								_item={_item}
								key={index}
							/>
						)
					})
				}
				</Stack>
			</Grid>
		</Grid>
	)
}