import { Box, Typography, Grid, Button, FormControl } from "@mui/material";



function DefaultView(props)
{
	return (
		<Box sx={{
			display:"flex",
			alignItems:"center",
			justifyContent:"center",
			align:"center",
			maxWidth:"80%",
			margin:"5% auto 5% auto"
		}}>

		<FormControl fullWidth>
			<Grid container spacing={2}>
				<Grid item xs={2}/>
				<Grid item xs={8}>
				<Typography variant="h2" component="div">
					This is a Proof of concept. Bienvenu :)
				</Typography>
				</Grid>
				<Grid item xs={2}/>
				<Grid item xs={2}/>
				<Grid item xs={2}>
					<Typography variant="h6" component="div">
						Liste des concepts :
					</Typography>
				</Grid>
				<Grid item xs={8}/>
				<Grid item xs={3}/>
				
				<Grid item xs={4}>
					<Button variant="contained" onClick={(event) => {window.location = "/LawyerCallForm"}}>
						Appel avocat (LaywerCallForm)
					</Button>
				</Grid>
				<Grid item xs={5}/>
				<Grid item xs={3}/>
				<Grid item xs={4}>
					<Button variant="contained" onClick={(event) => {window.location = "/ArrestReportForm"}}>
						Rapport d'arrestation (ArrestReportForm)
					</Button>
				</Grid>
				<Grid item xs={5}/>
				<Grid item xs={3}/>
				<Grid item xs={4}>
					<Button variant="contained" onClick={(event) => {window.location = "/FactReportForm"}}>
						Rapport des faits (FactReportForm)
					</Button>
				</Grid>
				<Grid item xs={5}/>
				<Grid item xs={3}/>
				<Grid item xs={4}>
					<Button variant="contained" onClick={(event) => {window.location = "/gdoc"}}>
						NE CLICKEZ PAS ICI SVP (google docs tests)
					</Button>
				</Grid>

			</Grid>
		</FormControl>
		</Box>
	)
}

export default DefaultView